<template>
  <div class=" mt-2">
    <b-breadcrumb class="breadcrumb-pipes">
      <b-breadcrumb-item href="/settings/admin-panel/">
        Admin Panel
      </b-breadcrumb-item>
      <b-breadcrumb-item href="/settings/user-role">
        Roles and Permissions
      </b-breadcrumb-item>
      <b-breadcrumb-item href="/settings/personal-settings">
        Personal Settings
      </b-breadcrumb-item>
    </b-breadcrumb>

    <b-card class="mb-0" :title="$t('settings.users.titles.manage_users')">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row align-h="between">
            <b-col cols="12" md="3">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                cle
                :placeholder="$t('general_text.search_table')"
              />
            </b-col>
            <b-col
              v-if="$Can('add_user') || loggedUserRole == 'supermanager'"
              cols="12"
              md="3"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="success" @click="createUserModal">
                  <span class="text-nowrap">
                    {{ $t("settings.users.buttons.create_new_user") }}
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUsersTable"
          class="position-relative p-2"
          :items="allUsers"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :fixed="true"
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('general_messages.no_matching_recors_found')"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(index)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.index + 1 }}</strong>
              </span></b
            >
          </template>
          <!-- Column: username -->
          <template #cell(name)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.name ? data.item.name : "N/A" }}</strong>
              </span></b
            >
          </template>

          <template #cell(surname)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.surname ? data.item.surname : "N/A"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              v-if="data.value == 'A'"
              pill
              :variant="`light-success`"
              class="text-capitalize"
            >
              {{ $t("settings.users.titles.active") }}
            </b-badge>

            <b-badge
              v-if="data.value == 'P'"
              pill
              :variant="`light-danger`"
              class="text-capitalize"
            >
              {{ $t("settings.users.titles.passive") }}
            </b-badge>
          </template>

          <!-- Column: Status -->
          <template #cell(role)="data">
            {{ data.value[0].toUpperCase() + data.value.slice(1) }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="$Can('edit_user') || loggedUserRole == 'supermanager'"
                @click="userDetail(data.item.id)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">
                  {{ $t("settings.users.buttons.update_user") }}</span
                >
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$Can('delete_user') || loggedUserRole == 'supermanager'"
                @click="deleteUser(data.item)"
              >
                <feather-icon style="color:#28c76f" icon="UnlockIcon" />
                <span class="align-middle ml-50">{{
                  $t("settings.users.buttons.delete_user")
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
      {{ /*user create modal*/ }}
      <b-modal
        id="modal-prevent-closing"
        style="overflow: inherit;"
        no-close-on-backdrop
        v-model="userCreateModal"
        :title="$t('settings.users.titles.create_new_user')"
        @hidden="resetUserModal"
        size="lg"
        :ok-title="$t('settings.users.buttons.create_user')"
        :cancel-title="$t('settings.users.buttons.cancel')"
        @ok="createUser"
      >
        <b-overlay :show="createUserloading" rounded="sm" no-fade>
          <b-container>
            <validation-observer ref="createValidation">
              <b-form>
                <b-row class="mt-2">
                  <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <!-- name-->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.users.form.name')"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="userData.name"
                          name="name"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.users.form.name')"
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <!-- surname-->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.users.form.surname')"
                        rules="required"
                      >
                        <b-form-input
                          id="surname"
                          v-model="userData.surname"
                          name="surname"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.users.form.surname')"
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <!-- email-->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.users.form.email')"
                        rules="required"
                      >
                        <b-form-input
                          id="email"
                          v-model="userData.email"
                          name="email"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.users.form.email')"
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <b-form-group label-for="role">
                      <validation-provider
                        name="role"
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          placeholder="User Role"
                          v-model="userData.role"
                          :options="allRoles"
                          :value="allRoles"
                          :clearable="false"
                          :taggable="false"
                          input-id="role"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col v-if="userData.role == 'client'">
                    <b-form-group>
                      <label class="compact_label">{{
                        $t("settings.users.form.client_accounts")
                      }}</label>

                      <v-select
                        class="compact_form_vue-multi-select"
                        label="AccountName"
                        v-model="accountNameSelected"
                        :reduce="(val) => val"
                        multiple
                        :selectable="() => accountNameSelected.length < 10"
                        :options="optAccountNames"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-container>
          <template #overlay v-if="createUserloading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" />
                {{ $t("settings.users.messages.user_creating_message") }}
              </p>
            </div>
          </template>
        </b-overlay>
        {{/****END*** user create modal*/}}
      </b-modal>
      {{ /*user update modal*/ }}
      <b-modal
        id="modal-prevent-closing"
        v-model="userUpdateModal"
        :title="$t('settings.users.titles.create_new_user')"
        @hidden="resetUserModal"
        size="lg"
        :ok-title="$t('settings.users.buttons.update_user')"
        :cancel-title="$t('settings.users.buttons.cancel')"
        @ok="updateUser"
        style="overflow: inherit;"
        no-close-on-backdrop
      >
        <b-overlay :show="updateUserloading" rounded="sm" no-fade>
          <b-container>
            <validation-observer ref="updateValidation">
              <b-form>
                <b-row class="mt-2">
                  <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <!-- name-->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.users.form.name')"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="userData.name"
                          name="name"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.users.form.name')"
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <!-- surname-->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.users.form.surname')"
                        rules="required"
                      >
                        <b-form-input
                          id="surname"
                          v-model="userData.surname"
                          name="surname"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.users.form.surname')"
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <!-- email-->
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('settings.users.form.email')"
                        rules="required"
                      >
                        <b-form-input
                          id="email"
                          v-model="userData.email"
                          name="email"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('settings.users.form.email')"
                          autofocus
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                    <b-form-group label-for="role">
                      <validation-provider
                        name="role"
                        #default="{ errors }"
                        rules="required"
                      >
                        <v-select
                          placeholder="User Role"
                          v-model="userData.role"
                          :options="allRoles"
                          :value="allRoles"
                          :clearable="false"
                          :taggable="false"
                          input-id="role"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col v-if="userData.role == 'client'">
                    <b-form-group>
                      <label class="compact_label">{{
                        $t("settings.users.form.client_accounts")
                      }}</label>

                      <v-select
                        class="compact_form_vue-multi-select"
                        label="AccountName"
                        v-model="accountNameSelected"
                        :reduce="(val) => val"
                        multiple
                        :selectable="() => accountNameSelected.length < 10"
                        :options="optAccountNames"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-container>
          <template #overlay v-if="createUserloading">
            <div
              class="text-center d-flex justify-content-center align-items-start"
            >
              <b-icon icon="stopwatch" font-scale="3" animation="cylon">
              </b-icon>
              <p id="cancel-label">
                <feather-icon icon="DownloadIcon" />
                {{ $t("settings.users.messages.user_creating_message") }}
              </p>
            </div>
          </template>
        </b-overlay>

        <template #modal-footer>
          <b-row class="align-items-center" style="flex: 1;">
            <!-- trade ok operation buttons -->

            <b-col
              cols="12"
              md="12"
              xl="12"
              lg="12"
              sm="12"
              class="d-flex justify-content-end align-items-center"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1 modal-btn"
                @click="sendNewPassword"
              >
                Send New Password
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                size="sm"
                class="ml-1 modal-btn"
                @click="resetUserModal"
              >
                {{ $t("settings.users.buttons.cancel") }}
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1 modal-btn"
                @click="updateUser"
              >
                {{ $t("settings.users.buttons.update_user") }}
              </b-button>
            </b-col>
          </b-row>
        </template>

        {{/****END*** user update modal*/}}
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    BBreadcrumb,
    BBreadcrumbItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      loggedUserRole: JSON.parse(localStorage.getItem("userData")).role,
      allUsers: [],
      userCreateModal: false,
      userUpdateModal: false,
      changedEmail: false,
      allRoles: [],
      filterCreateModal: null,
      filterCreateModalOn: ["TeamName"],
      updatePermloading: false,
      createUserloading: false,
      updateUserloading: false,
      confrimPassword: null,
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
      userData: {
        email: null,
        name: null,
        surname: null,
        password: null,
        role: null,
      },
      filterOn: ["username"],
      tableColumns: [
        {
          key: "index",
          label: "",
          class: "text-start pl-2",
        },
        {
          key: "name",
          label: this.$t("settings.users.table.user_name"),
          sortable: true,
          class: "text-start",
        },
        {
          key: "surname",
          label: this.$t("settings.users.table.surname"),
          sortable: true,
          class: "text-start",
        },
        {
          key: "role",
          label: this.$t("settings.users.table.role_name"),
          sortable: true,
          class: "text-start",
        },
        {
          key: "status",
          label: this.$t("settings.users.table.status"),
          sortable: true,
          class: "text-start",
        },

        {
          key: "actions",
          label: this.$t("settings.users.table.actions"),
          sortable: false,
        },
      ],
      detailTeamsColumns: [
        {
          key: "name",
          label: this.$t("settings.users.table.role_name"),
          sortable: false,
        },

        {
          key: "actions",
          label: "",
          sortable: false,
          class: "text-start",
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,

      loading: false,
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      accountNameSelected: [],
      optAccountNames: [],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  mounted() {
    this.getRoles();

    this.getUsers();
  },
  methods: {
    getUsers() {
      this.loading = true;
      store
        .dispatch("settings/fetchUsers", this.user)
        .then((res) => {
          this.allUsers = res.data.users;
          this.optAccountNames = res.data.accounts;

          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },
    createUserModal() {
      this.userCreateModal = true;
    },

    getRoles() {
      store
        .dispatch("settings/fetchRoles")
        .then((res) => {
          res.data.data.forEach((element) => {
            this.allRoles.push(element.name);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.createValidation.validate().then((success) => {
        if (success) {
          //filter true teams

          if (this.userData.role != null) {
            //encrypted password

            this.createUserloading = true;
            this.userData.accounts = this.accountNameSelected;
            store
              .dispatch("settings/createNewUser", {
                user: this.userData,
              })
              .then((res) => {
                if (res.data.info == "created") {
                  this.createUserloading = false;
                  this.resetUserModal();
                  this.getUsers();
                  this.createUserToastMessage();
                } else if (res.data.info == "exist_user") {
                  this.createUserloading = false;

                  this.errorToastMessage(res.data.message);
                } else {
                  this.createUserloading = false;

                  this.wrongToastMessage(
                    this.$t("settings.users.messages.user_added_error")
                  );
                }
              })
              .catch((error) => {
                this.createUserloading = false;

                console.log(error);
              });
          } else {
            this.createUserloading = false;
            this.onlySelectOneTeamMessage();
          }
        }
      });
    },
    updateUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.updateValidation.validate().then((success) => {
        if (success) {
          this.updateUserloading = true;
          let updateData = {
            id: this.userData.id,
            name: this.userData.name,
            surname: this.userData.surname,
            email: this.userData.email,
            role: this.userData.role,
            accounts: this.accountNameSelected,
          };

          store
            .dispatch("settings/updateUserData", {
              user: updateData,
              passChange: false,
              changedEmail: this.changedEmail,
            })
            .then((res) => {
              if (res.data.info == "updated") {
                this.updateUserloading = false;
                this.resetUserModal();
                this.getUsers();
                this.updateUserToastMessage();
              } else if (res.data.info == "exist_user") {
                this.updateUserloading = false;

                this.errorToastMessage(res.data.message);
              } else {
                this.updateUserloading = false;

                this.wrongToastMessage(
                  this.$t("settings.users.messages.user_updated_error")
                );
              }
            })
            .catch((error) => {
              this.updateUserloading = false;
              console.log(error);
            });
        }
      });
    },
    sendNewPassword(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.updateValidation.validate().then((success) => {
        if (success) {
          this.updateUserloading = true;

          store
            .dispatch("settings/sendNewPassword", {
              user: this.userData.id,
            })
            .then((res) => {
              if (res.data.info == "sent") {
                this.updateUserloading = false;
                this.sendPasswordToastMessage();
              } else {
                this.updateUserloading = false;

                this.wrongToastMessage(
                  this.$t("settings.users.messages.user_updated_error")
                );
              }
            })
            .catch((error) => {
              this.updateUserloading = false;
              console.log(error);
            });
        }
      });
    },
    userDetail(userId) {
      store
        .dispatch("settings/getuserDetail", {
          userId: userId,
        })
        .then((res) => {
          if (res.data.id != 0 && res.data.email != null) {
            this.userData = {
              id: res.data.id,
              email: res.data.email,
              name: res.data.name,
              surname: res.data.surname,
              password: null,
              role: res.data.role,
            };
            this.accountNameSelected = res.data.accounts;

            this.userUpdateModal = true;
          } else {
            this.wrongToastMessage(
              this.$t("settings.users.messages.get_user_error")
            );
            this.userUpdateModal = false;
          }
          // this.userTempData.userId = res.data.userDetails.id;
          // this.allRoles = res.data.roles;
          // this.currentUserRole = res.data.userDetails.role
        })
        .catch((error) => {
          this.userUpdateModal = false;
          console.log(error);
        });
    },

    resetUserModal() {
      this.userData = {
        userName: null,
        password: null,
        teams: [],
      };
      this.userCreateModal = false;
      this.userUpdateModal = false;
      this.confrimPassword = null;
      this.updateUserloading = false;
      this.accountNameSelected = [];
    },

    updateUserStatus(user) {
      const self = this;
      if (user.status == "A") {
        user.status = "P";
        this.$swal({
          title:
            this.$t("settings.users.messages.sure_delete_user") +
            ` ${user.name} ${user.surname}`,
          // text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("settings.users.buttons.yes_delete_it"),
          cancelButtonText: this.$t("settings.users.buttons.cancel"),
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            self.loading = true;
            store
              .dispatch("settings/updateUserStatus", {
                auth: this.user,
                user: user,
              })
              .then((res) => {
                if (res.data.info == true) {
                  self.getUsers();
                  self.loading = false;
                  self.$swal.fire({
                    icon: "success",
                    title: this.$t("settings.users.titles.deleted"),
                    text: this.$t("settings.users.messages.user_deleted"),
                    showClass: {
                      popup: "animate__animated animate__fadeInDown",
                    },
                  });
                } else {
                  this.userNotFoundMessage();
                  self.loading = false;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            self.getUsers();
          }
        });
      } else {
        user.status = "A";
        this.$swal({
          title:
            this.$t("settings.users.messages.sure_activate_user") +
            ` ${user.name} ${user.surname}`,
          // text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("settings.users.buttons.yes_activate_it"),
          cancelButtonText: this.$t("settings.users.buttons.cancel"),
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            self.loading = true;
            store
              .dispatch("settings/updateUserStatus", {
                auth: this.user,
                user: user,
              })
              .then((res) => {
                if (res.data.info == true) {
                  self.getUsers();
                  self.loading = false;
                  self.$swal.fire({
                    icon: "success",
                    title: "Activated!",
                    text: this.$t("settings.users.messages.user_activated"),
                    showClass: {
                      popup: "animate__animated animate__fadeInDown",
                    },
                  });
                } else {
                  this.userNotFoundMessage();
                  self.loading = false;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            self.getUsers();
          }
        });
      }
    },

    deleteUser(user) {
      const self = this;
      this.$swal({
        title:
          this.$t("settings.users.messages.sure_delete_user") +
          ` ${user.name} ${user.surname}`,
        // text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("settings.users.buttons.yes_delete_it"),
        cancelButtonText: this.$t("settings.users.buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-success mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;
          store
            .dispatch("settings/deleteUser", {
              auth: this.user,
              user: user,
            })
            .then((res) => {
              if (res.data.info == true) {
                self.getUsers();
                self.loading = false;
                self.$swal.fire({
                  icon: "success",
                  title: this.$t("settings.users.titles.deleted"),
                  text: this.$t("settings.users.messages.user_deleted"),
                  showClass: {
                    popup: "animate__animated animate__fadeInDown",
                  },
                });
              } else {
                this.userNotFoundMessage();
                self.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          self.getUsers();
        }
      });
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.users.messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    onlySelectOneTeamMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.users.messages.least_one_team"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    userNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.users.messages.user_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    doesntMatchPassToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("settings.users.messages.password_different_other"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    createUserToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("settings.users.messages.user_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    updateUserToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("settings.users.messages.user_update_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    sendPasswordToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("settings.users.messages.user_password_send_message"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    assignedUserToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("settings.users.messages.user_team_update"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },

    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
  },
  watch: {
    "userData.email": {
      handler: function(val, before) {
        if (before != null) {
          if (val != before) {
            this.changedEmail = true;
          }
        }
      },
    },
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes("to")) {
            this.filterDateStart = this.rangeDate.split(" to ")[0];
            this.filterDateEnd = this.rangeDate.split(" to ")[1];

            this.selectedEvents();
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
